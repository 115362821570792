
    /** Reusable Classes **/

    .form-input {
        @apply w-full mt-2 mb-0 px-4 py-2.5 md:py-3 border rounded-md text-gray-700 text-base placeholder-grey-4  focus:outline-none focus:border-primary read-only:bg-grey-1 focus:border-1.5 focus:invalid:border-danger focus:read-only:border-grey-2 focus:valid:border-primary dark:bg-dark-1 dark:border-dark dark:placeholder-dark-2 dark:text-dark-4;
    }

    .search-input {
        @apply w-80 mt-2 mb-2 pr-4 border text-grey-2 focus:outline-none rounded-md  pl-10 py-3.5 md:py-3  text-xs;
    }

    .custom-select {
        @apply form-input bg-white read-only:bg-white invalid:text-black-1 pr-10 drop-down-icon;
    }

    .form-label {
        @apply block font-medium text-sm ;
    }

    .btn {
        @apply text-sm hover:opacity-90 text-center py-2.5 px-4 font-medium rounded-md disabled:bg-grey-2 disabled:text-white;
    }

    .btn-primary {
        @apply btn bg-primary text-white hover:text-white;
    }

    .btn-brand {
        @apply btn bg-brand text-white;
    }

    .btn-danger {
        @apply btn bg-danger text-white;  
    }

    .btn-warn {
        @apply btn bg-warn text-white;  
    }

    .btn-outline {
        @apply btn text-black-1 border;
    }

    .btn-md {
        @apply  py-3.5 px-6 rounded-lg ;
    }

    .btn-lg {
        @apply  py-4 px-8 rounded-lg md:rounded-xl ;
    }

    .btn-link {
        @apply font-medium hover:opacity-80
    }

    .link {
        @apply font-medium text-sm text-primary hover:opacity-85 underline;
    }

    .drop-down-icon {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14.6 1.45801L9.16667 6.89134C8.525 7.53301 7.475 7.53301 6.83333 6.89134L1.4 1.45801' stroke='%2302050A' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        -webkit-print-color-adjust: exact;
        background-repeat: no-repeat;
        background-position: right 1rem center;
        background-size: 0.6rem 0.6rem;
    }

    .drop-down-icon.phone-icon {
        background-position: right 0.55rem center;
    }

    .dark .drop-down-icon {
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M14.6 1.45801L9.16667 6.89134C8.525 7.53301 7.475 7.53301 6.83333 6.89134L1.4 1.45801' stroke='%23FFFFFF' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3e%3c/svg%3e");
    }

    .drop-down-icon-fill {
        @apply drop-down-icon;  
        background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 10 5' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5 5L0.5 0.5H9.5L5 5Z' fill='black' /%3e%3c/svg%3e");
    }

    .drop-down-edge {
        background-position: right 0rem center;
    }