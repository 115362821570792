
// No Authenticated Layout
.auth {
    @apply min-h-[100vh] flex align-middle;

    > div {
        // @apply min-w-[100%]  lg:max-w-5xl mx-auto lg:my-auto;
    }
}

// Authenticated Layout

.cont {
    @apply flex w-full h-full relative top-0 text-sm;

    > .placeholder {
        @apply flex-none w-0 lg:w-64;
    }

    > aside {
        @apply fixed bottom-0 -left-64 lg:left-0  top-0 text-grey-5 w-64 bg-white px-4 border-r z-60; transition: left 0.5s;

        @screen lg {
            transition: none;
        }

        &.show {
            @apply left-0;
        }

        .navlink-cont {
            @apply py-3;

            li{
                @apply py-1;

                > a,  > div {
                    @apply inline-block w-full  text-base py-1.5 rounded;
                    span {
                        @apply border-l-4 inline-block px-5 border-white py-0.5;

                        svg {
                            @apply inline-block mr-3 w-5 ; 
                        }
    
                        svg.swap-svg:nth-child(2) {
                            @apply hidden;
                        }
                    }

                    &:hover:not(.no-link), &.active {
                        @apply bg-brand-light font-medium text-brand ;
                        span {
                            @apply border-brand;
                            
                            svg.swap-svg:first-child {
                                @apply hidden;
                            }

                            svg.swap-svg:nth-child(2) {
                                @apply inline-block;
                            }
                        }
                    }
                }

            }
        }

        .scrollable-nav {
            @apply overflow-y-auto flex flex-col bottom-5 absolute top-14 right-0 left-0 ;
        }
        .lower-nav {
            @apply absolute bottom-0 pb-4 pt-1 left-0 right-0 bg-white dark:bg-dark-1 dark:text-white;
        }
    }

    > main {
        @apply flex-1 ;

        > .main-cont {
            @apply px-6 sm:px-8 2xl:px-10 pb-20;

            .sticky-1 {
                @apply flex py-5 text-sm sticky top-14 flex-wrap h-[4.1rem] leading-8 z-40 bg-white;
            }

            .sticky-2 {
                @apply  flex sticky top-[7.6rem] flex-wrap z-40 border-b bg-white;
            }

            .sticky-3 {
                @apply  flex lg:-mx-6 sticky top-[11.2rem] flex-wrap z-40 border-b bg-white;
            }

            .cont-area {
                min-height: calc(100vh - 7.5rem);

                &.no-nav{
                    min-height: calc(100vh - 3rem);
                }
            }
        }
    }
}
