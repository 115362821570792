
/**

Tailwind Custom styling and CSS Override

*/

@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
    @import './base';
    @import './layout';
    @import './custom-class';
    @import './custom-table';
    @import './custom-toggle';
    @import './popover';
    @import './country-select';
    @import './react-loading-skeleton';

}

@import './toast';
@import './datepicker';

